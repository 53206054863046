import { translateRouterHref } from '../components/share/AppLink'
import { useTranslation } from './UserTranslation'

export const useTranslateLink = () => {
  const { currentLanguage } = useTranslation()
  return (href: string) => {
    if (!href) {
      return
    }
    try {
      const url = new URL(href)
      url.pathname = translateRouterHref(url.pathname, currentLanguage)
      return url.toString()
    } catch (e) {
      return
    }
  }
}
